<template>
  <b-card class="w-100 facilities">
    <b-card-title>
      {{ !parentId ? 'Option' : 'Sub Option' }}
    </b-card-title>
    <b-card-sub-title>
      This section is for the facilities that are available in your property.
      These facilities will be seen by guests when they search for a place to
      stay.
    </b-card-sub-title>
    <div>
      <validation-observer ref="tour-option">
        <!-- Basic Input-->
        <b-row class="my-1 service-charge border-bottom">
          <!-- Name -->
          <b-col md="6">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="name"
                    v-model="optionData.name"
                  />
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Capacity -->
          <b-col md="6">
            <b-form-group
              label="Capacity"
              label-for="capacity"
            >
              <validation-provider
                #default="{ errors }"
                name="capacity"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="capacity"
                    v-model="optionData.capacity"
                  />
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Base Price -->
          <b-col md="6">
            <b-form-group
              label="Base Price"
              label-for="base-price"
            >
              <validation-provider
                #default="{ errors }"
                name="base price"
                rules="required"
              >
                <b-input-group :prepend="currencyCode ? currencyCode : 'BDT'">
                  <b-form-input
                    id="base-price"
                    v-model="optionData.base_price"
                  />
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Child Base Price-->
          <b-col md="6">
            <b-form-group
              label="Child Base Price"
              label-for="child-base-price"
            >
              <b-input-group :prepend="currencyCode ? currencyCode : 'BDT'">
                <b-form-input
                  id="child-base-price"
                  v-model="optionData.base_price_child"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- Category -->
          <b-col
            v-if="!parentId"
            md="6"
          >
            <b-form-group
              label="Category"
              label-for="category"
            >
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <v-select
                  id="category"
                  v-model="optionData.category"
                  :options="categoryList"
                  label="display_name"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Subcategory Text-->
          <template v-if="optionData.category">
            <b-col
              v-if="!parentId && isObjectEmpty(optionData.category)"
              md="6"
            >
              <p class="subcategory-text text-success m-0 font-weight-bold">
                <feather-icon
                  :icon="
                    optionData.category.child_unit_type ? 'CheckIcon' : 'XIcon'
                  "
                  size="16"
                  class="align-middle text-body"
                />
                SubOption
              </p>
              <p class="m-0">
                {{
                  optionData.category.parent_unit_type === 'IGNORE'
                    ? 'Option will be ignored'
                    : `Option will be based on ${optionData.category.parent_unit_type}`
                }}
              </p>
              <p class="m-0">
                {{
                  optionData.category.has_child
                    ? optionData.category.child_unit_type === 'IGNORE'
                      ? 'Sub Option will be ignored'
                      : `Sub Option will be based on ${optionData.category.child_unit_type}`
                    : null
                }}
              </p>
            </b-col>
          </template>
          <!-- Descriptoin -->
          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="description"
            >
              <quill-editor
                ref="description"
                v-model="optionData.description"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Order"
              label-for="order"
            >
              <b-form-input
                id="order"
                v-model.number="optionData.order_id"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1 service-charge border-bottom">
          <!-- Addon List -->
          <b-col md="6">
            <h6 class="text-bold mb-1 text-primary">
              Add-ons
            </h6>
            <b-form-group
              label="Select Addons"
              label-for="addons"
            >
              <vue-autosuggest
                v-model="addonQuery"
                :input-props="{
                  id: 'autosuggest__input',
                  class: 'form-control',
                  placeholder: 'Search..',
                }"
                :suggestions="[{ data: addonsList }]"
                :limit="10"
                @focus="debounceSearch(0, getAddonList)"
                @input="debounceSearch(0, getAddonList)"
                @selected="getSelectedAddon"
              >
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item text-truncate">
                    {{ suggestion.item.name }}
                    {{
                      suggestion.item.description
                        ? ` - ${suggestion.item.description}`
                        : ''
                    }}</span>
                </template>
              </vue-autosuggest>
              <div class="text-right mt-1">
                <small class="text-primary">
                  <i class="fa fa-info-circle" /> Create a new Add-on if it
                  isn't listed
                </small>
                <b-button
                  id="add-period"
                  size="sm"
                  variant="outline-primary"
                  @click="openForm('addon')"
                >
                  Create Add-on
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <!-- Addon List Table -->
          <b-col md="12">
            <b-table
              class=""
              responsive
              :fields="addonsTableCol"
              :items="optionData.add_on"
              show-empty
              empty-text="No records found"
            >
              <template #cell(actions)="data">
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="cursor-pointer text-danger"
                  @click="deleteAddon(data.item.id)"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- Pickup Time List -->
        <b-row class="my-1 border-bottom">
          <b-col md="6">
            <h6 class="text-bold mb-1 text-primary">
              Pick-up
            </h6>
            <b-form-group
              label="Select Pickup"
              label-for="pickuptime"
            >
              <vue-autosuggest
                v-model="pickupTimeQuery"
                :input-props="{
                  id: 'autosuggest__input',
                  class: 'form-control',
                  placeholder: 'Search..',
                }"
                :suggestions="[{ data: pickupTimeList }]"
                :limit="10"
                @focus="debounceSearch(0, getPickupTimeList)"
                @input="debounceSearch(0, getPickupTimeList)"
                @selected="getSelectedPickupTime"
              >
                <template slot-scope="{ suggestion }">
                  <span class="suggestion-text">{{
                    suggestion.item.text
                  }}</span>
                </template>
              </vue-autosuggest>
              <div class="text-right mt-1">
                <small class="text-primary">
                  <i class="fa fa-info-circle" /> Create a new Pick-up if it
                  isn't listed
                </small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="openForm('pickup')"
                >
                  Create Pick-up
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <!-- Pickup Times Table-->
          <b-col md="12">
            <b-table
              responsive
              :fields="pickupTimeTableCol"
              :items="optionData.pick_up"
              show-empty
              empty-text="No records found"
            >
              <template #cell(actions)="data">
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="cursor-pointer text-danger"
                  @click="deletePickupTime(data.item.id)"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- Image -->
        <b-row class="my-1 border-bottom">
          <b-col md="6">
            <h6 class="text-bold mb-1 text-primary">
              Pictures
            </h6>
            <b-form-group
              label="Upload Pictures"
              label-for="pictures"
            >
              <b-form-file
                id="pictures"
                v-model="imageFiles"
                accept="image/jpeg, image/png, image/jpg"
                multiple
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @input="uploadImageFiles"
              />
            </b-form-group>
          </b-col>
          <!-- Image Preview -->
          <b-col md="12">
            <div class="mb-2">
              <draggable
                v-model="optionData.pictures"
                class="d-flex"
                @change="changeImageOrder($event, option.pictures)"
              >
                <div
                  v-for="(img, i) in optionData.pictures"
                  :key="img.id"
                  class="img-box mr-1  p-1 border"
                >
                  <feather-icon
                    icon="XIcon"
                    size="12"
                    class="close-icon"
                    @click="removeImage(img, i)"
                  />
                  <b-img
                    :src="img.img"
                    alt="option image"
                    class="w-100 h-100"
                  />
                </div>
              </draggable>
            </div>
          </b-col>
        </b-row>
        <!-- Multiplicable -->
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Multiplicable"
              label-for="multiplicable"
            >
              <validation-provider
                #default="{ errors }"
                name="multiplicable"
                rules="required"
              >
                <b-form-radio-group
                  id="multiplicable"
                  v-model="optionData.is_multiplicable"
                  :options="multiplicableOptions"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Submit -->
        <b-row
          align-h="between"
          class="facility-names mb-2"
        />
        <div class="text-right mb-1">
          <b-button
            variant="outline-primary"
            class="mx-1"
            @click="closeOptionForm"
          >
            Close
          </b-button>
          <b-button
            variant="primary"
            :disabled="isBtnLoading"
            @click="isTourOptionValid()"
          >
            <b-spinner
              v-if="isBtnLoading"
              small
            />
            Submit
          </b-button>
        </div>
      </validation-observer>
    </div>
    <AddonForm
      :tour-id="tourId"
      :currency-code="currencyCode"
      @addAddon="getSelectedAddon"
    />
    <PickupForm
      :tour-id="tourId"
      @addPickup="getSelectedPickupTime"
    />
  </b-card>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import draggable from 'vuedraggable'
import { quillEditor } from 'vue-quill-editor'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'
import AddonForm from '@/views/addons/AddonForm.vue'
import PickupForm from '@/views/pickup/PickupForm.vue'

export default {
  name: 'OptionForm',
  components: {
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    draggable,
    AddonForm,
    PickupForm,
    quillEditor,
  },
  mixins: [helper, tour],
  props: {
    categoryList: {
      type: Array,
    },
    optionInfo: {
      type: Object,
    },
    tourId: {
      type: Number,
    },
    parentId: {
      type: Number,
    },
    currencyCode: {
      type: String,
      default: 'BDT',
    },
  },
  data() {
    return {
      optionData: {},
      addonsList: [],
      pickupTimeList: [],
      isBtnLoading: false,
      addonQuery: '',
      pickupTimeQuery: '',
      imageFiles: [],
      optionImages: [],
      multiplicableOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      addonsTableCol: [
        {
          key: 'name',
          thClass: 'md',
          formatter: (value, _, item) => (value ? `${value} ${item.description ? ` - ${item.description}` : ''}` : ''),
        },
        {
          key: 'max_capacity',
          label: 'Quantity',
          thClass: 'md',
        },
        {
          key: 'unit',
          thClass: 'md',
          label: 'Capacity',
        },
        {
          key: 'price',
          thClass: 'md',
          formatter: (value, _, item) => (value ? `${item.currency_code || this.currencyCode} ${Number(value).toLocaleString()}` : ''),
        },
        { key: 'actions', label: '' },
      ],
      pickupTimeTableCol: [
        {
          key: 'text',
          thClass: 'md',
        },
        {
          key: 'price',
          thClass: 'md',
          formatter: (value, _, item) => (value
            ? `${item.currency_code || this.currencyCode} ${Number(
              value,
            ).toLocaleString()}`
            : ''),
        },
        {
          key: 'time',
          thClass: 'md',
        },
        { key: 'actions', label: '' },
      ],
    }
  },
  mounted() {
    if (this.isObjectEmpty(this.optionInfo)) {
      this.optionData = { ...this.optionInfo }
    } else {
      this.optionData = {
        add_on: [],
        pick_up: [],
        pictures: [],
        category: null,
      }
    }
  },
  methods: {
    getAddonList() {
      const search = this.addonQuery ? `&search=${this.addonQuery}` : ''
      api.getData(`tour/admin_services/addons_list/?tour_id=${this.tourId}${search}`, true)
        .then(response => {
          if (response.data.status) {
            this.addonsList = response.data.result.results
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        })
        .catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        })
    },
    isTourOptionValid() {
      this.$refs['tour-option'].validate().then(success => {
        if (success) {
          this.createOrUpdateOption()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    getSelectedAddon(data) {
      this.addonQuery = data.item ? data.item.name : data.name
      this.optionData.add_on.push(data.item || data)
    },
    getPickupTimeList() {
      const search = this.pickupTimeQuery ? `&search=${this.pickupTimeQuery}` : ''
      api.getData(`tour/admin_services/pickup_time_list/?tour_id=${this.tourId}${search}`, true)
        .then(response => {
          if (response.data.status) {
            this.pickupTimeList = response.data.result.results
          }
        })
        .catch(() => {})
    },
    getSelectedPickupTime(data) {
      this.pickupTimeQuery = data.item ? data.item.text : data.text
      this.optionData.pick_up.push(data.item || data)
    },
    closeOptionForm() {
      this.$emit('closeOptionForm')
    },
    deleteAddon(id) {
      this.optionData.add_on = this.optionData.add_on.filter(
        addon => addon.id !== id,
      )
    },
    openForm(type) {
      if (type === 'addon') {
        this.$root.$emit('bv::toggle::collapse', 'addon-form')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'pickuptime-form')
      }
    },
    deletePickupTime(id) {
      this.optionData.pick_up = this.optionData.pick_up.filter(pu => pu.id !== id)
    },
    uploadImageFiles() {
      const maxIndex = this.optionData.pictures.length ? this.optionData.pictures.length : 1
      this.imageFiles.forEach((file, i) => {
        const formData = new FormData()
        formData.append('img', file)
        formData.append('p_index', maxIndex + i)
        api.postData('tour/admin_services/tour_picture/', true, formData).then(res => {
          this.optionData.pictures.push(res.data.result)
        })
      })
    },
    removeImage(image, index) {
      const formData = {
        id: image.id,
        p_index: image.p_index,
      }
      api.updateData(`tour/admin_services/tour_picture/${image.id}/?delete_old=true`, true, formData)
        .then(response => {
          if (response.data.status) {
            this.optionData.pictures.splice(index, 1)
            this.createOrUpdateOption()
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Picture removed successfully')
            this.$emit('getTourData')
          }
        })
        .catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
        })
    },
    prepareOptionData() {
      const formData = { ...this.optionData }
      formData.pictures = this.optionData.pictures.map(picture => picture.id)
      formData.add_on = this.optionData.add_on.map(addon => addon.id)
      formData.pick_up = this.optionData.pick_up.map(pickUp => pickUp.id)
      if (this.parentId) {
        delete formData.category
        formData.parent = this.parentId
      }
      if (!this.parentId) {
        formData.category = this.optionData.category && this.optionData.category.id
          ? this.optionData.category.id
          : this.optionData.category
      }
      const optionId = formData.id
      return [optionId, formData]
    },
    createOrUpdateOption() {
      this.isBtnLoading = true
      let url = 'tour/admin_services/option_create/'
      let apiResponse
      const [optionId, formData] = this.prepareOptionData()
      if (optionId) {
        url = `tour/admin_services/option_update/${optionId}/`
        apiResponse = api.updateData(url, true, formData)
      } else {
        formData.tour = this.tourId
        apiResponse = api.postData(url, true, formData)
      }
      apiResponse
        .then(response => {
          if (response.data.status) {
            this.notificationMessage('success', 'CheckIcon', 'Success', `option ${optionId ? 'updated' : 'created'} successfully`)
            this.$emit('getTourData')
            this.closeOptionForm()
          } else {
            this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
          }
        })
        .catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
        })
        .finally(() => {
          this.isBtnLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/tour.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
.subcategory-text svg {
  stroke: var(--green);
}
.img-box {
  width: 150px;
  height: 150px;
  position: relative;
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #eceaff;
    padding: 5px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
  }
}
</style>
