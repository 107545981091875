<template>
  <b-card>
    <b-card-title
      class="text-primary d-flex align-items-center justify-content-between"
    >
      <span>
        Options
      </span>
      <b-button
        variant="primary"
        @click="openOptionForm()"
      >
        Add option
      </b-button>
    </b-card-title>
    <div class="border-top pt-2">
      <div
        v-for="(option,i) in optionList"
        :key="option.id"
        class="options mb-4"
      >
        <div
          class="d-flex align-items-center justify-content-between mb-1 option"
        >
          <div>
            <h6 class="mb-0 font-weight-bold text-primary">
              {{ option.name }}
            </h6>
          </div>
          <h6 class="mb-0">
            Capacity - {{ option.capacity }}
          </h6>
          <h6 class="mb-0">
            Price - {{ currencyCode ? currencyCode : 'BDT' }} {{ Number(option.base_price).toLocaleString() }}
          </h6>
          <div>
            <b-button
              variant="primary"
              size="sm"
              @click="openOptionForm(option)"
            >
              Edit Option {{ i+1 }}
            </b-button>
            <b-button
              :disabled="!(option.category && option.category.child_unit_type)"
              variant="outline-primary"
              size="sm"
              class="ml-1"
              @click="openOptionForm(option,'sub-option')"
            >
              Add Sub-option
            </b-button>
            <b-button
              v-b-modal.option-modal
              variant="outline-danger"
              class="ml-1"
              size="sm"
              @click="selectedOption = option"
            >
              <feather-icon
                class="cursor-pointer"
                icon="TrashIcon"
                size="16"
              />
            </b-button>
          </div>
        </div>
        <b-table
          id="reportTable"
          :items="option.child_options"
          primary-key="id"
          responsive
          show-empty
          :fields="tableColumns"
          class="position-relative"
          :sort-by.sync="sortBy"
        >
          <template #cell(actions)="data">
            <b-badge
              pill
              variant="light-primary"
              class="cursor-pointer"
              @click="getSubOptionData(data.item,option)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="16"
              />
              Edit
            </b-badge>
            <b-badge
              v-b-modal.option-modal
              pill
              variant="light-danger"
              class="ml-1 cursor-pointer"
              @click="selectedOption = data.item"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
              Delete
            </b-badge>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="option-modal"
      ref="option-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      title="Delete"
    >
      <b-card-text>
        Are you sure you want to delete this option ?
      </b-card-text>
      <template #modal-footer>
        <b-button
          variant="danger"
          :disabled="isRequestOnProgress"
          @click="deleteDayOption()"
        >
          <b-spinner
            v-if="isRequestOnProgress"
            small
          />
          Confirm
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'OptionList',
  mixins: [helper],
  props: {
    optionList: {
      type: Array,
    },
    currencyCode: {
      type: String,
      default: 'BDT',
    },
  },
  data() {
    return {
      isRequestOnProgress: false,
      sortBy: 'order_id',
      selectedOption: {},
      tableColumns: [
        {
          key: 'name', label: 'Sub-Option Name', thClass: 'md',
        },
        {
          key: 'capacity', label: 'Capacity', thClass: 'md',
        },
        {
          key: 'base_price', label: 'Base Price', thClass: 'md',
        },
        { key: 'actions', label: 'Action', thClass: 'md' },
      ],
    }
  },
  methods: {
    openOptionForm(option, formType) {
      this.$emit('openOptionForm', option, formType)
    },
    getSubOptionData(subOption, option) {
      this.$emit('getSubOptionData', subOption, option)
    },
    deleteDayOption() {
      this.isRequestOnProgress = true
      api.deleteData(`tour/admin_services/option_delete/${this.selectedOption.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.$emit('getOptionList')
            this.$root.$emit('bv::hide::modal', 'option-modal')
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Option has been deleted successfully')
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', '')
        }).finally(() => {
          this.isRequestOnProgress = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
.options {
  border-radius: 5px;
  background-color: #fcfaff;
  .option {
    padding: 10px 10px 0 10px;
  }
}
.dark-layout {
  .options {
    background-color: #1a233a;
  }
}
</style>
